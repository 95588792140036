import LogRocket from "logrocket";
import { useEffect } from "react";
import { useAppStore } from "store/useAppStore";

export const useIdentifyLogRocketUser = () => {
  const user = useAppStore.use.user();

  useEffect(() => {
    if (!user) {
      return;
    }

    LogRocket.identify(String(user.userId), {
      displayName: user.displayName,
      email: user.email,
      companyId: user.companyId || 0,
      companyName: user.company || "",
      agencyId: user.businessEntityId || 0,
      agencyName: user.businessEntity || "",
    });
  }, [user]);
};
