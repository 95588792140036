import {
  ImpersonationOverlay,
  ImpersonationSelectUserModal,
  useCheckForUpdates,
} from "components";
import { ConfirmDialogRoot } from "components/ConfirmDialog/ConfirmDialogRoot";
import { ContentLibraryDropzone } from "components/ContentLibrary/ContentLibraryDropzone";
import { ContentLibraryUploadProgress } from "components/ContentLibrary/ContentLibraryUploadProgress";
import "focus-visible/dist/focus-visible";
import { Providers } from "providers/Providers";
import { useAppStore } from "store/useAppStore";
// import { useUserCompanyTrialEndDate } from "queries/companies/useUserCompanyTrialEndDate";
import { useIdentifyAmplitudeUser } from "tracking/useIdentifyAmplitudeUser";
import { useIdentifyLogRocketUser } from "tracking/useIdentifyLogRocketUser";
import { contentLibraryUpload } from "upload/contentLibraryUpload";
import { useWatchLocation } from "utils";
import { useRegisterClarityMetadata } from "utils/useRegisterClarityMetadata";
import { useScrollToTop } from "utils/useScrollToTop";

const InnerApp = () => {
  // eslint-disable-next-line no-console
  console.debug(`build: ${String(process.env.VITE_CLIENT_VERSION)}`);

  const isImpersonationModalOpen = useAppStore.use.isImpersonationModalOpen();
  const setIsImpersonationModalOpen =
    useAppStore.use.setIsImpersonationModalOpen();

  useScrollToTop();
  useCheckForUpdates();
  useIdentifyAmplitudeUser();
  useRegisterClarityMetadata();
  useIdentifyLogRocketUser();
  // useUserCompanyTrialEndDate();
  useWatchLocation();

  return (
    <>
      <div
        id="chart-export-portal"
        style={{ position: "absolute", top: -9999, left: -9999 }}
      />
      <ContentLibraryDropzone />
      <ContentLibraryUploadProgress tusUpload={contentLibraryUpload} />
      <ImpersonationOverlay />
      <ConfirmDialogRoot />
      <ImpersonationSelectUserModal
        isOpen={isImpersonationModalOpen}
        onClose={() => {
          setIsImpersonationModalOpen(false);
        }}
      />
    </>
  );
};

export const App = () => {
  return (
    <Providers>
      <InnerApp />
    </Providers>
  );
};
