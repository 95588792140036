import {
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  chakra,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, DynamicInputNext } from "components";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useComponentColors } from "theme";
import { PageTrackingScreenDefinition } from "screens";
import { z } from "zod";
import { useState } from "react";
import { MutateCompanyPixelDTO } from "@intentsify/dto";
import { ampli } from "../../../../../tracking/amplitude";

type Props = {
  companyId: number;
  pixelId?: number;
  pixelName?: string;
  domains?: string[];
  mode: "create" | "edit";
  isLoading: boolean;
  onSubmit: (params: MutateCompanyPixelDTO) => void;
};

export const modifyPixelSchema = z.object({
  displayName: z
    .string()
    .min(3)
    .trim()
    .regex(
      /^intentsify-[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*$/,
      "String must start with 'intentsify-' followed by alphanumeric parts separated by hyphens"
    ),
  domains: z
    .array(z.string())
    .min(1, { message: "At least one valid domain is required." }),
});

export type ModifyPixelFormState = z.infer<typeof modifyPixelSchema>;

const PixelForm = ({
  mode,
  pixelName,
  domains = [],
  isLoading,
  onSubmit,
  pixelId,
}: Props) => {
  const componentColors = useComponentColors();
  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
    clearErrors,
  } = useForm<ModifyPixelFormState>({
    mode: "onChange",
    resolver: zodResolver(modifyPixelSchema),
    defaultValues: {
      displayName: pixelName,
      domains,
    },
  });

  const formValues = watch();

  const [inputValue, setInputValue] = useState<string>("");

  const modifyDomains = () => {
    const newDomains = [...formValues.domains, inputValue];
    setValue("domains", newDomains);
    if (newDomains.length > 0) {
      clearErrors("domains");
    }
    ampli.companyPixelTrackedWebsiteAdded({ pixelId });
    setInputValue("");
  };

  return (
    <chakra.form
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={handleSubmit((data) => {
        onSubmit(data);
      })}
      borderRadius={4}
    >
      <Text
        color={componentColors.form.formLabelColor}
        fontSize="lg"
        fontWeight="bold"
      >
        {mode === "edit" ? "Edit Pixel" : "Create Pixel"}
      </Text>
      <FormControl
        w="400px"
        mt={4}
        isInvalid={!!errors?.displayName?.message}
        isRequired
      >
        <FormLabel
          color={componentColors.form.formLabelColor}
          fontSize="sm"
          fontWeight="bold"
        >
          Name
        </FormLabel>
        <Input
          {...register("displayName")}
          type="name"
          placeholder="Pixel"
          shadow="sm"
          size="sm"
          rounded="md"
        />
        <FormErrorMessage>{errors?.displayName?.message}</FormErrorMessage>
      </FormControl>
      <DynamicInputNext.Root alignItems={"flex-start"}>
        <DynamicInputNext.Body>
          <Text
            p="0"
            marginTop={4}
            fontSize="sm"
            fontWeight="bold"
            color={componentColors.form.formLabelColor}
          >
            Tracked Websites
          </Text>
          <InputGroup size="md">
            <FormControl isInvalid={!!errors?.domains?.message}>
              <Input
                name="domain"
                value={inputValue}
                mt={1}
                placeholder="Website"
                shadow="sm"
                size="sm"
                rounded="md"
                autoComplete="off"
                onChange={(e) => {
                  setInputValue(e.target.value);
                }}
              />
              <FormErrorMessage>{errors?.domains?.message}</FormErrorMessage>
            </FormControl>
            <InputRightElement>
              <Button onClick={modifyDomains} size="sm">
                Add
              </Button>
            </InputRightElement>
          </InputGroup>
        </DynamicInputNext.Body>
        <DynamicInputNext.Tag
          tags={formValues.domains}
          onRemoveClick={(removed) => {
            setValue(
              "domains",
              formValues.domains.filter((domain) => domain !== removed)
            );
          }}
        />
      </DynamicInputNext.Root>

      <Flex mt={4} justifyContent="flex-end">
        <ButtonGroup>
          <Button
            size="md"
            onClick={() => navigate(PageTrackingScreenDefinition.navigate())}
          >
            Cancel
          </Button>
          <Button
            size="md"
            type="submit"
            variant="primary-teal"
            isLoading={isLoading}
          >
            {mode === "create" ? "Create" : "Edit"}
          </Button>
        </ButtonGroup>
      </Flex>
    </chakra.form>
  );
};

export { PixelForm };
