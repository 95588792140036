import { apiService, Endpoints } from "api";
import {
  GetWebAnalyticsCustomersByEmployeeSizeRO,
  GetWebAnalyticsCustomersByGeographyRO,
  GetWebAnalyticsCustomersByRevenueSizeRO,
  SiteEngagementLiftRO,
  GetWebAnalyticsStatisticsRO,
  GetWebAnalyticsWebsiteVisitsRO,
  WebAnalyticsDTO,
  GetWebAnalyticsTopVisitedUrlsRO,
} from "@intentsify/dto";
import { valueToQueryURL } from "utils";

const getStatistics = async (
  pixelId: number,
  params: WebAnalyticsDTO
): Promise<GetWebAnalyticsStatisticsRO> => {
  const { data } = await apiService.get<GetWebAnalyticsStatisticsRO>(
    Endpoints.WebAnalytics.Get.Statistics(pixelId),
    {
      params: {
        ...params,
        accountsIds: params.accountsIds?.length
          ? valueToQueryURL(params.accountsIds)
          : undefined,
        visitedPages: params.visitedPages?.length
          ? valueToQueryURL(params.visitedPages)
          : undefined,
      },
    }
  );

  return data;
};

const getWebsiteVisits = async (
  pixelId: number,
  params: WebAnalyticsDTO
): Promise<GetWebAnalyticsWebsiteVisitsRO> => {
  const { data } = await apiService.get<GetWebAnalyticsWebsiteVisitsRO>(
    Endpoints.WebAnalytics.Get.WebsiteVisits(pixelId),
    {
      params: {
        ...params,
        accountsIds: params.accountsIds?.length
          ? valueToQueryURL(params.accountsIds)
          : undefined,
        visitedPages: params.visitedPages?.length
          ? valueToQueryURL(params.visitedPages)
          : undefined,
      },
    }
  );

  return data;
};

const getSiteEngagementLift = async (
  pixelId: number,
  campaignId: number
): Promise<SiteEngagementLiftRO> => {
  const { data } = await apiService.get<SiteEngagementLiftRO>(
    Endpoints.WebAnalytics.Get.SiteEngagementLift(pixelId, campaignId)
  );

  return data;
};

const getTopVisitedUrls = async (
  pixelId: number,
  params: WebAnalyticsDTO
): Promise<GetWebAnalyticsTopVisitedUrlsRO> => {
  const { data } = await apiService.get<GetWebAnalyticsTopVisitedUrlsRO>(
    Endpoints.WebAnalytics.Get.TopVisitedUrls(pixelId),
    {
      params: {
        ...params,
        accountsIds: params.accountsIds?.length
          ? valueToQueryURL(params.accountsIds)
          : undefined,
        visitedPages: params.visitedPages?.length
          ? valueToQueryURL(params.visitedPages)
          : undefined,
      },
    }
  );

  return data;
};

const getCustomersByGeography = async (
  pixelId: number,
  params: WebAnalyticsDTO
): Promise<GetWebAnalyticsCustomersByGeographyRO> => {
  const { data } = await apiService.get<GetWebAnalyticsCustomersByGeographyRO>(
    Endpoints.WebAnalytics.Get.CustomersByGeography(pixelId),
    {
      params: {
        ...params,
        accountsIds: params.accountsIds?.length
          ? valueToQueryURL(params.accountsIds)
          : undefined,
        visitedPages: params.visitedPages?.length
          ? valueToQueryURL(params.visitedPages)
          : undefined,
      },
    }
  );

  return data;
};

const getCustomersByEmployeeSize = async (
  pixelId: number,
  params: WebAnalyticsDTO
): Promise<GetWebAnalyticsCustomersByEmployeeSizeRO> => {
  const { data } =
    await apiService.get<GetWebAnalyticsCustomersByEmployeeSizeRO>(
      Endpoints.WebAnalytics.Get.CustomersByEmployeeSize(pixelId),
      {
        params: {
          ...params,
          accountsIds: params.accountsIds?.length
            ? valueToQueryURL(params.accountsIds)
            : undefined,
          visitedPages: params.visitedPages?.length
            ? valueToQueryURL(params.visitedPages)
            : undefined,
        },
      }
    );

  return data;
};

const getCustomersByRevenueSize = async (
  pixelId: number,
  params: WebAnalyticsDTO
): Promise<GetWebAnalyticsCustomersByRevenueSizeRO> => {
  const { data } =
    await apiService.get<GetWebAnalyticsCustomersByRevenueSizeRO>(
      Endpoints.WebAnalytics.Get.CustomersByRevenueSize(pixelId),
      {
        params: {
          ...params,
          accountsIds: params.accountsIds?.length
            ? valueToQueryURL(params.accountsIds)
            : undefined,
          visitedPages: params.visitedPages?.length
            ? valueToQueryURL(params.visitedPages)
            : undefined,
        },
      }
    );

  return data;
};

export {
  getStatistics,
  getWebsiteVisits,
  getSiteEngagementLift,
  getTopVisitedUrls,
  getCustomersByGeography,
  getCustomersByEmployeeSize,
  getCustomersByRevenueSize,
};
