import { ArrowDownIcon, ArrowUpIcon, MinusIcon } from "@chakra-ui/icons";
import { Box } from "@chakra-ui/react";
import { ProgrammaticCampaignAccountData } from "@intentsify/types";
import {
  decimalToPercentage,
  isDefined,
  toCurrency,
  toNumberDisplayValue,
} from "@intentsify/utils";
import { ColumnDef } from "@tanstack/react-table";
import { useMemo } from "react";
import { EMPTY_CELL } from "components";

export const getColumns = (
  hasVisits: boolean,
  hasConversions: boolean
): ColumnDef<ProgrammaticCampaignAccountData>[] => {
  const columns: Array<ColumnDef<ProgrammaticCampaignAccountData> | undefined> =
    [
      {
        header: "Domain",
        accessorKey: "domainName",
      },
      {
        header: "Impressions",
        accessorFn: (row) => toNumberDisplayValue(row.impressions ?? 0),
      },
      {
        header: "Clicks",
        accessorFn: (row) => toNumberDisplayValue(row.clicks ?? 0),
      },
      {
        header: "CTR",
        accessorKey: "ctr",
        cell: (info) => {
          const trend = Number(info.row.original?.ctrVsPrevious);
          return (
            <Box>
              {toNumberDisplayValue(
                decimalToPercentage(info.row.original?.ctr ?? 0),
                { digits: 2 }
              )}
              %
              {!isNaN(trend) && (
                <span style={{ marginLeft: "5px" }}>
                  {trend > 0 && <ArrowUpIcon color="green.400" />}
                  {trend === 0 && <MinusIcon color="blackAlpha.600" />}
                  {trend < 0 && <ArrowDownIcon color="red.400" />}
                </span>
              )}
            </Box>
          );
        },
      },
      {
        header: "Video Views",
        accessorFn: (row) =>
          row.videoPlays === 0
            ? EMPTY_CELL
            : toNumberDisplayValue(row.videoPlays),
      },
      {
        header: "Average % Watched",
        accessorFn: (row) =>
          row.videoCompletionRate === 0
            ? EMPTY_CELL
            : `${toNumberDisplayValue(row.videoCompletionRate * 100)}%`,
      },
      hasConversions
        ? {
            header: "Conversions",
            accessorFn: (row) => toNumberDisplayValue(row.conversions ?? 0),
          }
        : undefined,
      {
        header: "Buyer Research Stage",
        accessorFn: (row) => row.buyerResearchStage ?? EMPTY_CELL,
      },
      hasVisits
        ? {
            header: "Total Site Visits",
            accessorFn: (row) => toNumberDisplayValue(row.totalSiteVisits ?? 0),
            sortingFn: (rowA, rowB) =>
              rowA.original.totalSiteVisits - rowB.original.totalSiteVisits,
          }
        : undefined,
      {
        header: "Spend",
        accessorFn: (row) =>
          row.spend ? toCurrency(row.spend ?? 0) : EMPTY_CELL,
      },
    ];

  return columns.filter(isDefined);
};

export const useDataForExport = (
  data: ProgrammaticCampaignAccountData[],
  hasVisits: boolean,
  hasConversions: boolean
) => {
  const formatted = useMemo(() => {
    return data
      .map((i) => {
        return [
          ["Domain", i.domainName],
          ["Spend", toCurrency(i.spend ?? 0)],
          ["Impressions", toNumberDisplayValue(i.impressions ?? 0)],
          ["Clicks", toNumberDisplayValue(i.clicks ?? 0)],
          [
            "CTR",
            `${toNumberDisplayValue(decimalToPercentage(i.ctr ?? 0), {
              digits: 2,
            })}%`,
          ],
          [
            "Vide Views",
            i.videoPlays === 0
              ? EMPTY_CELL
              : toNumberDisplayValue(i.videoPlays),
          ],
          [
            "Average % Watched",
            i.videoCompletionRate === 0
              ? EMPTY_CELL
              : `${toNumberDisplayValue(i.videoCompletionRate * 100)}%`,
          ],
          hasConversions
            ? ["Conversions", toNumberDisplayValue(i.conversions ?? 0)]
            : undefined,
          hasVisits
            ? [
                "Total Site Visits",
                toNumberDisplayValue(i.totalSiteVisits ?? 0),
              ]
            : undefined,
        ];
      })
      .map((i) => Object.fromEntries(i.filter(isDefined)));
  }, [data, hasVisits, hasConversions]);

  return formatted;
};
