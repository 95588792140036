import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  SimpleGrid,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { GetFiltersRO } from "@intentsify/dto";
import {
  BuyingGroupFilters,
  brsOptions,
  buyingGroupFilters,
  topicStageOptions,
} from "@intentsify/types";
import { Button, RangeFilter, Select } from "components";
import { Controller, DefaultValues, useForm } from "react-hook-form";

type FiltersProps = {
  filtersOptions: GetFiltersRO;
  initialValues: DefaultValues<BuyingGroupFilters>;
  onApply: (value: BuyingGroupFilters) => void;
};

const Filters = ({ filtersOptions, initialValues, onApply }: FiltersProps) => {
  const form = useForm<BuyingGroupFilters>({
    resolver: zodResolver(buyingGroupFilters),
    defaultValues: initialValues,
  });

  const errors = form.formState.errors as typeof form.formState.errors & {
    general?: { message: string };
  };

  return (
    <Flex as="form" direction={"column"} gap={4}>
      <SimpleGrid spacing={"4"} columns={4} width="100%">
        <FormControl>
          <FormLabel fontSize="xs" fontWeight="semibold">
            Buyer Research Stage
          </FormLabel>
          <Controller
            name="buyerResearchStage"
            control={form.control}
            render={({ field }) => (
              <Select
                isMulti
                placeholder="Select BRS stages"
                options={brsOptions}
                value={brsOptions.filter((option) =>
                  field.value?.includes(option.value)
                )}
                onChange={(value) => {
                  if (value && Array.isArray(value)) {
                    field.onChange(value.map((option) => option.value));
                  }
                }}
              />
            )}
          />

          <FormErrorMessage>
            {errors.buyerResearchStage?.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl>
          <FormLabel fontSize="xs" fontWeight="semibold">
            Topics
          </FormLabel>
          <Controller
            name="topics"
            control={form.control}
            render={({ field: { onChange, value } }) => (
              <Select
                isMulti
                placeholder="Topics"
                value={filtersOptions.topics.filter((option) =>
                  value?.includes(option.label)
                )}
                options={filtersOptions.topics}
                isLoading={!filtersOptions.topics}
                onChange={(value) => {
                  if (value && Array.isArray(value)) {
                    onChange(value.map((option) => option.value));
                  }
                }}
              />
            )}
          />

          <FormErrorMessage>{errors.topics?.message}</FormErrorMessage>
        </FormControl>

        <FormControl>
          <FormLabel fontSize="xs" fontWeight="semibold">
            Keywords
          </FormLabel>
          <Controller
            name="keywords"
            control={form.control}
            render={({ field: { onChange, value } }) => (
              <Select
                isMulti
                placeholder="Keywords"
                value={filtersOptions.keywords.filter((option) => {
                  return value?.includes(option.label);
                })}
                options={filtersOptions.keywords}
                isLoading={!filtersOptions.keywords}
                onChange={(value) => {
                  if (value && Array.isArray(value)) {
                    onChange(value.map((option) => option.value));
                  }
                }}
              />
            )}
          />
          <FormErrorMessage>{errors.keywords?.message}</FormErrorMessage>
        </FormControl>

        <FormControl>
          <FormLabel fontSize="xs" fontWeight="semibold">
            Topic Stage
          </FormLabel>
          <Controller
            name="topicStage"
            control={form.control}
            render={({ field: { onChange, value } }) => (
              <Select
                isMulti={false}
                placeholder="Topic Stage"
                options={topicStageOptions}
                value={topicStageOptions.find(
                  (option) => option.value === value
                )}
                onChange={(value) => {
                  if (value && Array.isArray(value)) {
                    onChange(value.map((option) => option.value));
                  }
                }}
              />
            )}
          />
          <FormErrorMessage>{errors.topicStage?.message}</FormErrorMessage>
        </FormControl>

        <FormControl>
          <FormLabel fontSize="xs" fontWeight="semibold">
            Accounts
          </FormLabel>
          <Controller
            name="domains"
            control={form.control}
            render={({ field: { onChange, value } }) => (
              <Select
                isMulti
                placeholder="Accounts"
                value={filtersOptions.domains.filter((option) => {
                  return value?.includes(option.label);
                })}
                options={filtersOptions.domains}
                onChange={(value) => {
                  if (value && Array.isArray(value)) {
                    onChange(value.map((option) => option.value));
                  }
                }}
              />
            )}
          />
          <FormErrorMessage>{errors.domains?.message}</FormErrorMessage>
        </FormControl>

        <FormControl>
          <FormLabel fontSize="xs" fontWeight="semibold">
            Custom Sources
          </FormLabel>
          <Controller
            name="customSources"
            control={form.control}
            render={({ field: { onChange, value } }) => (
              <Select
                isMulti
                placeholder="Custom Sources"
                options={filtersOptions.customSources}
                value={filtersOptions.customSources.find((option) => {
                  return value?.includes(option.label);
                })}
                onChange={(value) => {
                  if (value && Array.isArray(value)) {
                    onChange(value.map((option) => option.value));
                  }
                }}
              />
            )}
          />
          <FormErrorMessage>{errors.customSources?.message}</FormErrorMessage>
        </FormControl>
        <FormControl>
          <FormLabel fontSize="xs" fontWeight="semibold">
            Active Sources
          </FormLabel>
          <Controller
            name="activeSources"
            control={form.control}
            render={({ field: { onChange } }) => (
              <RangeFilter
                step={1}
                min={0}
                max={10}
                onChange={onChange}
                value={initialValues.activeSources as [number, number]}
              />
            )}
          />
          <FormErrorMessage>{errors.activeSources?.message}</FormErrorMessage>
        </FormControl>
      </SimpleGrid>

      <SimpleGrid mt={4} spacing={"4"} columns={5} width="100%">
        <FormControl isInvalid={!!errors?.minLeads?.message} isRequired>
          <Text fontSize="xx-small">(Content Syndication)</Text>
          <FormLabel fontSize="xs" fontWeight="semibold">
            Min. Leads Delivered
          </FormLabel>
          <Controller
            name="minLeads"
            control={form.control}
            render={({ field: { value, onChange } }) => (
              <NumberInput
                shadow="sm"
                size="sm"
                rounded="md"
                maxWidth="200px"
                defaultValue={0}
                max={Number.MAX_SAFE_INTEGER}
                min={0}
                step={1}
                value={value}
                onChange={(e) => {
                  onChange(Number(e));
                }}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            )}
          ></Controller>
          <FormErrorMessage>{errors?.minLeads?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors?.minImpressions?.message} isRequired>
          <Text fontSize="xx-small">(Ad Solutions)</Text>
          <FormLabel fontSize="xs" fontWeight="semibold">
            Min. Impressions Served
          </FormLabel>
          <Controller
            name="minImpressions"
            control={form.control}
            render={({ field: { value, onChange } }) => (
              <NumberInput
                shadow="sm"
                size="sm"
                rounded="md"
                maxWidth="200px"
                defaultValue={0}
                max={Number.MAX_SAFE_INTEGER}
                min={0}
                step={1}
                value={value}
                onChange={(e) => {
                  onChange(Number(e));
                }}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            )}
          ></Controller>
          <FormErrorMessage>{errors?.minImpressions?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors?.minClicks?.message} isRequired>
          <Text fontSize="xx-small">(Ad Solutions)</Text>
          <FormLabel fontSize="xs" fontWeight="semibold">
            Min. Clicks
          </FormLabel>
          <Controller
            name="minClicks"
            control={form.control}
            render={({ field: { value, onChange } }) => (
              <NumberInput
                shadow="sm"
                size="sm"
                rounded="md"
                maxWidth="200px"
                defaultValue={0}
                max={Number.MAX_SAFE_INTEGER}
                min={0}
                step={1}
                value={value}
                onChange={(e) => {
                  onChange(Number(e));
                }}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            )}
          ></Controller>
          <FormErrorMessage>{errors?.minClicks?.message}</FormErrorMessage>
        </FormControl>
      </SimpleGrid>

      <Flex alignItems="center" gap="4" alignSelf="end" mt="8">
        <Button
          variant="secondary"
          onClick={() => {
            onApply(form.getValues());
          }}
        >
          Apply Filters
        </Button>
      </Flex>
    </Flex>
  );
};

export { Filters };
